import { Col, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { useContext } from "react";
import { action, handleDispatch, TixboxContext } from "store";

export const Colors = ({ colors }) => {
  const { dispatch } = useContext(TixboxContext);
  const handleChange = handleDispatch(dispatch);
  return (
    <Form.Group as={Row}>
      {colors.map(({ formLabel, id, defaultValue }, index) => (
        <Col
          xs={6}
          key={id}
          className={`p-3 ${index % 2 === 0 ? "border-end" : ""}`}
        >
          <Row>
            <Col sm={3}>
              <Form.Control
                type="color"
                id={id}
                defaultValue={defaultValue}
                title={formLabel}
                onChange={handleChange(action.changed[id])}
              />
            </Col>
            <Form.Label htmlFor={id} column sm={9} className="ps-3">
              {formLabel}
            </Form.Label>
          </Row>
        </Col>
      ))}
    </Form.Group>
  );
};
