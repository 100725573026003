import { useContext } from "react";
import { TixboxContext } from "store";
import { removeHTMLTags } from "helpers";

export const useAggregatorProps = (props) => {
  const {
    state: {
      iframeConfig: { targetBlank },
    },
  } = useContext(TixboxContext);
  const {
    name,
    slug,
    url,
    slogan,
  } = props;
  
  return {
    name: removeHTMLTags(name),
    slug,
    url,
    slogan,
  };
};
