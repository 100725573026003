import { useContext, useEffect, useState } from "react";
import { action, handleDispatch, TixboxContext } from "store";
import { useKeyPress } from "./useKeyPress";

export const useSearchItems = (events, searchQuery) => {
  const [searchResults, setSearchResults] = useState([]);
  const {
    state: {
      iframeConfig: { searchBar },
    },
    dispatch,
  } = useContext(TixboxContext);

  const onCtrlKPress = (event) =>
    (event.metaKey || event.ctrlKey) && event.key === "k";

  const [isSearchOn, setIsSearchOn] = useState(searchBar);
  useKeyPress(onCtrlKPress, () => {
    setIsSearchOn((prevState) => !prevState);
    handleDispatch(dispatch)(action.changed.searchBar)(isSearchOn);
  });

  useEffect(() => {
    const filteredResults = events.filter(({ name }) =>
      name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filteredResults);
  }, [searchQuery, events]);

  return { searchResults, searchBar };
};
