import styled from "styled-components";
import { useSearchItems } from "hooks";

import { EventTile } from ".";

const StyledEventList = styled.ul`
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 15px;
  list-style: none;
  display: grid;
  grid-gap: 25px;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(24em, 1fr));
  }
`;

export const EventsList = ({ events, searchQuery }) => {
  const { searchResults } = useSearchItems(events, searchQuery);

  return (
    <>
      <StyledEventList>
        {searchResults?.map((eventProps) => (
          <EventTile
            key={eventProps["slug"] || eventProps["order_url"]}
            {...eventProps}
          />
        ))}
      </StyledEventList>
    </>
  );
};
