import { useContext } from "react";
import { action, createAction, TixboxContext } from "store";
import { useMatch } from "react-router-dom";
import { useHover } from "@uidotdev/usehooks";
import { routePath } from "routing";
import { Styled } from "./StyledComponents";
import { useAggregatorProps, usePickedItems } from "hooks";
import { CTAButton, EventDetails, ItemPicker } from ".";

export const AggregatorItem = (props) => {
  const {
    state: {
      iframeConfig: {
        imageBorderRadius,
        imageSize,
        background,
        color,
        targetBlank,
      },
    },
    dispatch,
  } = useContext(TixboxContext);
  const [pickerRef, isPickerHovered] = useHover();
  const isSetup = !!useMatch(routePath.tixboxSetup);
  const { name, slug, logo, url, slogan } = useAggregatorProps(props);
  const { isPickedAggregator, setPickedAggregators } = usePickedItems(slug);
  const { target, rel } = {
    target: targetBlank ? "_blank" : undefined,
    rel: targetBlank ? "noreferrer" : undefined,
  };

  const trimmedName =
  name[55] === undefined ? name : `${name.trim().slice(0, 50)}…`;

  const openModal = (url) => {
    dispatch(createAction(action.eventModal.toggle));
    dispatch(createAction(action.eventModal.setOrderUrl, url));
  };

  const handleTileClick = (event, url) => {
    if (!targetBlank) {
      event.preventDefault();
      openModal(url);
    }
  };

  return <>
    <Styled.EventItem
      role="article"
      className="p-3"
      $textColor={color}
      $background={background}
      $faded={isSetup && slug && !isPickedAggregator}
    >
      {isSetup && slug && (
        <div ref={pickerRef}>
          <ItemPicker item={{ slug, name }} isHovered={isPickerHovered}  isPicked={isPickedAggregator} setPicked={setPickedAggregators} />
        </div>
      )}
      <Styled.EventContent
        $textColor={color}
        style={{
          gridTemplateColumns: `calc(${imageSize} * 1px) minmax(5rem, 1fr)`,
        }}
      >

        <Styled.EventCover
          href={logo}
          onClick={(e) => handleTileClick(e, url)}
          target={target}
          rel={rel}
        >
          <img
            src={""}
            alt={name}
            className="img-thumbnail"
            style={{ borderRadius: `calc(${imageBorderRadius} * 1%)` }}
          />
        </Styled.EventCover>

        <figcaption>
          <h2 className="event-title" title={name}>
            <a
              href={url}
              onClick={(e) => handleTileClick(e, url)}
              target={target}
              rel={rel}
              className="text-decoration-none"
            >
              {trimmedName}
            </a>
          </h2>
          <div className="event-location__place">{slogan}</div>
        </figcaption>
      </Styled.EventContent>
      <CTAButton isDisabled={false} url={url} />
    </Styled.EventItem>
  </>
}
