import { useMatch } from "react-router-dom";
import { routePath } from "routing";

export const useBodyBg = () => {
  const isPublicView = !!useMatch(routePath.eventsView);
  const setBodyBg = (color) => {
    document.querySelector("body").style.setProperty("--bs-body-bg", color);
  };
  if (isPublicView) setBodyBg("transparent");
};
