import { useContext, useMemo } from "react";
import { initialState, TixboxContext } from "store";
import { cleanUndef, shortenParamNames, subtractApiParams } from "helpers";
import { IFRAME_BASE_URL } from "store/initial";
import { useIframeBgColor } from "./useIframeBgColor";

export const getParams = (apiMode, events = [], aggregators = [], iframeConfig) => {
  const subtractedParams = subtractApiParams(
    {
      ...iframeConfig,
      footerHtml:
        iframeConfig.footerHtml === initialState.iframeConfig.footerHtml
          ? undefined
          : iframeConfig.footerHtml,
      events: events.length ? events.map(({ slug }) => slug) : undefined,
      aggregators: aggregators.length ? aggregators.map(({ slug }) => slug) : undefined,
    },
    apiMode
  );
  const shortenedParams = shortenParamNames(subtractedParams);
  const cleanedParams = cleanUndef(shortenedParams);
  return new URLSearchParams(cleanedParams)
    .toString()
    .replace(/(=&)|(=$)/gm, (match, g1, g2) => (g1 ? "&" : g2 && ""));
};
export const useIframeCode = (mode = "") => {
  const {
    state: {
      iframeConfig,
      ui: { apiMode, pickedEvents, pickedAggregators },
    },
  } = useContext(TixboxContext);

  const { bgColor } = useIframeBgColor();

  const selectedMode = mode || apiMode;

  const [memoApiMode, memoEvents, memoAggregators, memoConfig] = useMemo(
    () => [selectedMode, pickedEvents, pickedAggregators, iframeConfig],
    [selectedMode, pickedEvents, pickedAggregators, iframeConfig]
  );

  const processedParamsString = useMemo(
    () => getParams(memoApiMode, memoEvents, memoAggregators, memoConfig),
    [memoApiMode, memoEvents, memoAggregators, memoConfig]
  );

  const background = `background-color:${bgColor}`;

  const urlParamsString = "?" + processedParamsString;
  const iframeCode = `<iframe\n    src="${
    IFRAME_BASE_URL + urlParamsString
  }"\n    style="border:none;${background}">\n</iframe>`;
  return [iframeCode, urlParamsString];
};
