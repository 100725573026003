import { useContext, useEffect } from "react";
import { useLocation, useMatch } from "react-router-dom";

import { action, handleDispatch, TixboxContext } from "store";
import { routePath } from "routing";
import { decodeParamNames } from "helpers";

export const useTileSearchParamsSetup = () => {
  const { dispatch } = useContext(TixboxContext);

  const isPublicView = !!useMatch(routePath.eventsView);
  const { search } = useLocation();

  const tryParse = (val) => {
    try {
      return JSON.parse(val);
    } catch {
      return val;
    }
  };

  useEffect(() => {
    if (isPublicView) {
      const searchParams = new URLSearchParams(search);
      let data = {};
      searchParams.forEach((value, name) => {
        data = { ...data, [name]: tryParse(value) };
      });
      const {
        imageSize,
        background,
        color,
        buttonColor,
        buttonBg,
        buttonLabel,
        imageBorderRadius,
        searchBar,
        footerHtml,
        newTab,
        header,
      } = decodeParamNames(data);
      handleDispatch(dispatch)(action.read.urlSearch)({
        imageSize,
        background,
        color,
        buttonColor,
        buttonBg,
        buttonLabel,
        imageBorderRadius,
        searchBar,
        footerHtml,
        header,
        targetBlank: newTab || newTab === "",
      });
    }
  }, [isPublicView, search, dispatch]);
};
