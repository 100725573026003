import { uiReducer } from "./uiReducer";
import { eventModalReducer } from "./eventModalReducer";
import { changedReducer } from "./changedReducer";
import { readReducer } from "./readReducer";
import { devReducer } from "./devReducer";

const reducers = {
  CHANGED: changedReducer,
  UI: uiReducer,
  EVENT_MODAL: eventModalReducer,
  READ: readReducer,
  DEV: devReducer,
};

export default reducers;
