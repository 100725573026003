import { FloatingLabel, Form } from "react-bootstrap";

export const TextInput = ({
  id,
  formLabel,
  placeholder,
  defaultValue,
  onChange,
  className,
}) => {
  return (
    <FloatingLabel label={placeholder}>
      <Form.Control
        placeholder={placeholder}
        aria-label={formLabel}
        aria-describedby={id}
        id={id}
        defaultValue={defaultValue}
        onChange={onChange}
        className={className}
      />
    </FloatingLabel>
  );
};
