import { useContext, useEffect } from "react";
import { action, handleDispatch, TixboxContext } from "store";
import { useFetchedEvents } from "api";
import { removeHTMLTags } from "helpers";

export const useEventFilters = (id) => {
  const {
    state: {
      ui: { filterSet },
    },
    dispatch,
  } = useContext(TixboxContext);
  const { events } = useFetchedEvents();
  const filters = filterSet[id] || [];
  const setFilters = (filters) =>
    handleDispatch(dispatch)(action.ui.setFilters)({ id, filters });
  const getOptionsSet = (fetchedEvents) =>
    [
      ...new Set(fetchedEvents.map((event) => removeHTMLTags(event[id]))),
    ].sort();

  useEffect(() => {
    setFilters(
      getOptionsSet(events).map((name) => ({
        isSelected: true,
        name,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [filters, setFilters];
};
