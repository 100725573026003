import { useState } from "react";
import { Button } from "react-bootstrap";

import { Styled } from "./StyledComponents";

export const ItemPicker = ({ item, isHovered, isPicked, setPicked }) => {
  const { slug } = item;
  const [justChanged, setJustChanged] = useState(false);
  const handlePick = () => {
    setJustChanged(true);
    return setPicked(item);
  };
  const icon = {
    add: !isPicked && "bi-plus",
    remove: isHovered && isPicked && !justChanged && "bi-dash",
    picked: "bi-check",
  };
  const pickedCls = icon.add || icon.remove || icon.picked;

  return (
    <Styled.ItemPicker className={`event-picker ${isPicked && "d-block"}`}>
      <Button
        onClick={handlePick}
        onMouseLeave={() => setJustChanged(false)}
        className={`w-100 h-100 p-0 bg-primary rounded rounded-5 lh-1 opacity-100 shadow text-white h5 bi ${pickedCls}`}
        tabIndex="0"
        aria-label={isPicked ? "Usuń wybór" : "Dodaj wydarzenie"}
      />
    </Styled.ItemPicker>
  );
};
