import { useEffect } from "react";

export const useKeyPress = (eventHandler, callback) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (eventHandler(event)) callback();
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [callback, eventHandler]);
};
