import { useContext, useEffect } from "react";

import { action, handleDispatch, TixboxContext } from "store";
import content from "tixboxSettingsContent";

import { HR, Switch } from "components";
import { DataSourceSetup } from "./DataSourceSetup";
import { Colors } from "./Colors";
import { Misc } from "./Misc";

export const SetupPanel = () => {
  const { state, dispatch } = useContext(TixboxContext);
  const {
    iframeConfig,
    ui: { apiMode },
  } = state;

  const { colors, orderPageType, searchBar } = content;
  const handleChange = handleDispatch(dispatch);

  useEffect(() => {
    handleChange(action.changed.iframeUrl);
  }, [handleChange, apiMode]);

  return (
    <>
      <DataSourceSetup />
      <Switch
        {...orderPageType}
        defaultChecked={iframeConfig.targetBlank}
        onChange={handleChange(action.changed.targetBlank)}
      />
      <Switch
        {...searchBar}
        onChange={handleChange(action.changed.searchBar)}
        defaultChecked={iframeConfig.searchBar}
      />
      <HR />
      <Colors colors={colors} />
      <HR />
      <Misc />
    </>
  );
};
