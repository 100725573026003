import { Card } from "react-bootstrap";
import { SetupPanel } from "components";

export const Configuration = ({ title }) => (
  <Card id={Configuration.name}>
    <Card.Header>
      <i className="bi bi-1-circle-fill me-2"></i>
      {title}
    </Card.Header>
    <Card.Body>
      <SetupPanel />
    </Card.Body>
  </Card>
);
