import { Button, Tab } from "react-bootstrap";

import { useEventFilters } from "hooks";

const PropertyTable = ({ id }) => {
  const [filters, setFilters] = useEventFilters(id);
  const handleToggleOption = (opt) => {
    setFilters(
      filters.map(({ isSelected, name }) =>
        name === opt ? { isSelected: !isSelected, name } : { isSelected, name }
      )
    );
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(6em, 50%))",
      }}
    >
      {filters.map(({ isSelected, name }) => (
        <Button
          key={name}
          variant={isSelected ? "light" : "outline-secondary"}
          id={name}
          size="sm"
          className="me-2 mb-2 border-0 align-content-start"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "left",
          }}
          title={name}
          onClick={() => handleToggleOption(name)}
        >
          <i
            className={` me-2 bi ${
              isSelected ? "bi-toggle-on text-primary opacity-75" : "bi-toggle-off"
            }`}
          />
          {name}
        </Button>
      ))}
    </div>
  );
};
export const FiltersView = ({ properties, isEmpty }) =>
  !isEmpty ? (
    <Tab.Content>
      {!!properties?.length &&
        properties.map(({ id }) => (
          <Tab.Pane key={id} eventKey={id}>
            <PropertyTable id={id} />
          </Tab.Pane>
        ))}
    </Tab.Content>
  ) : (
    <div className="d-flex h-100 align-items-center justify-content-center h4">
      <i className="bi bi-stars text-secondary" />
    </div>
  );
