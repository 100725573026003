import { useContext } from "react";
import { Accordion, Badge, Form, InputGroup } from "react-bootstrap";

import content from "tixboxSettingsContent";
import { action, handleDispatch, TixboxContext } from "store";
import { useFilteredEvents, usePickedItems } from "hooks";
import { Picked, SearchFilters } from ".";
import { InfoTooltip, StackHeader } from "components";

const {
  eventSearch: { query },
} = content;

export const TabSearchApi = () => {
  const {
    state: { iframeConfig },
    dispatch,
  } = useContext(TixboxContext);

  const handleChange = handleDispatch(dispatch);
  const { pickedEvents } = usePickedItems();
  const { filteredEvents, filterSet } = useFilteredEvents();

  const pickedNum = pickedEvents.length;
  const hiddenEvents = pickedEvents.filter(({ slug }) =>
    filteredEvents.every(({ slug: filteredSlug }) => filteredSlug !== slug)
  );

  const flatFilters = Object.values(filterSet).flat();
  const isAnyEventFiltered = !flatFilters.every(
    ({ isSelected }) => isSelected === true
  );
  const activeFiltersNum = flatFilters.filter(
    ({ isSelected }) => !isSelected
  ).length;

  return (
    <>
      <InputGroup>
        <InputGroup.Text>
          <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
          id={query.id}
          aria-label={query.formLabel}
          placeholder={`np. ${query.defaultValue}`}
          onChange={handleChange(action.changed.searchQuery)}
          defaultValue={iframeConfig.q}
        />
      </InputGroup>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="picked-events">
          <Accordion.Header>
            <StackHeader>
              <Picked.EventsHeader
                iframeConfig={iframeConfig}
                pickedAmount={pickedNum}
                hiddenNumber={hiddenEvents.length}
                title="Wybrane wydarzenia"
              />
            </StackHeader>
          </Accordion.Header>
          <Accordion.Body className={pickedNum && "p-0"}>
            {pickedNum ? <Picked.EventsList /> : <Picked.EventsInfo />}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="filters">
          <Accordion.Header>
            <StackHeader>
              <div>
                Pokaż/ukryj
                <InfoTooltip message="To filtrowanie nie wpływa na wybór wydarzeń" />
              </div>
              {isAnyEventFiltered && (
                <div className="me-4">
                  <Badge
                    bg="warning"
                    title="Aktywne filtry"
                    className="text-warning-emphasis"
                  >
                    <i className="bi bi-exclamation-triangle-fill me-1" />
                    {activeFiltersNum} filtr{activeFiltersNum > 1 && "y"}
                  </Badge>
                </div>
              )}
            </StackHeader>
          </Accordion.Header>
          <Accordion.Body>
            <SearchFilters />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
