import { A_TYPE } from "store";

const { UI } = A_TYPE;

export const uiReducer = (store) => {
  switch (store.action.type) {
    case UI.ExpandWidget:
      return mutate.expandWidget(store);
    case UI.ApiMode:
      return mutate.apiMode(store);
    case UI.SetFilters:
      return mutate.setFilters(store);
    case UI.PickEvent:
      return mutate.pickEvent(store);
    case UI.PickAggregator:
      return mutate.pickAggregator(store);      
    case UI.IframeStyle:
      return mutate.iframeStyle(store);
    default:
      return store.state;
  }
};

const mutate = {
  expandWidget: ({ action, state }) => {
    const { payload } = action;
    const { horizontallyExpandedWidgets } = state.ui;
    const currentId = payload;
    const updatedWidgetsIds = horizontallyExpandedWidgets.includes(currentId)
      ? horizontallyExpandedWidgets.filter((id) => id !== currentId)
      : [...horizontallyExpandedWidgets, currentId];
    return {
      ...state,
      ui: { ...state.ui, horizontallyExpandedWidgets: updatedWidgetsIds },
    };
  },
  apiMode: ({ action: { payload }, state }) => ({
    ...state,
    ui: { ...state.ui, apiMode: payload },
  }),
  setFilters: ({
    action: {
      payload: { id, filters },
    },
    state,
  }) => ({
    ...state,
    ui: {
      ...state.ui,
      filterSet: { ...state.ui.filterSet, [id]: filters },
    },
  }),
  pickEvent: ({
    action: {
      payload: { isPickedEvent, event },
    },
    state,
  }) => {
    const pickedEvents = isPickedEvent
      ? [...state.ui.pickedEvents, event]
      : state.ui.pickedEvents.filter(
          (pickedEvent) => pickedEvent.slug !== event.slug
        );

    return { ...state, ui: { ...state.ui, pickedEvents } };
  },
  pickAggregator: ({
    action: {
      payload: { isPickedAggregator, aggregator },
    },
    state,
  }) => {
    const pickedAggregators = isPickedAggregator
      ? [...state.ui.pickedAggregators, aggregator]
      : state.ui.pickedAggregators.filter(
          (pickedAggregator) => pickedAggregator.slug !== aggregator.slug
        );

    return { ...state, ui: { ...state.ui, pickedAggregators } };
  },  
  iframeStyle: ({ action: { payload }, state }) => {
    const { iframeStyle } = state.ui;
    const style = { ...iframeStyle, ...payload };

    return { ...state, ui: { ...state.ui, iframeStyle: style } };
  },
};
