import { useState, useContext } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { SearchBar } from "../events-list/SearchBar";

import {
  useFilteredEvents,
  useTileSearchParamsSetup,
  useBodyBg,
  useIframeProp,
} from "hooks";

import { EventsList, AggregatorsList, Footer, Loader } from "components";
import { TixboxContext } from "store";

import { EventModal } from ".";

export const EventsView = () => {
  const {
    state: {
      iframeConfig: { searchBar },
    },
  } = useContext(TixboxContext);

  const {
    filteredEvents: events,
    filteredAggregators: aggregators,
    queryResult: { isLoading },
  } = useFilteredEvents();

  const [searchQuery, setSearchQuery] = useState("");
  const [headerContent] = useIframeProp("header");

  const handleSearch = ({ target }) => {
    setSearchQuery(target.value);
  };

  useTileSearchParamsSetup();
  useBodyBg();

  return (
    <>
      <section className="d-flex flex-column flex-grow-1">
        {headerContent && (
          <Container fluid className="my-3">
            <Row>
              <Col>
                <header dangerouslySetInnerHTML={{ __html: headerContent }} />
              </Col>
            </Row>
          </Container>
        )}
        {events?.length || aggregators?.length ? (
          <>
            {
              searchBar && <SearchBar handleSearch={handleSearch} searchQuery={searchQuery} />
            }
            { aggregators?.length > 0 && <AggregatorsList aggregators={aggregators} searchQuery={searchQuery} /> }
            { events?.length > 0 && <EventsList events={events} searchQuery={searchQuery} /> }
          </>
        ) : (
          <Loader alt="ładowanie…" isLoading={isLoading} /> || (
            <Alert
              variant="secondary"
              className="fs-3 mt-3 flex-grow-1 d-flex align-items-center justify-content-center opacity-75"
            >
              Niestety, nie znaleźliśmy żadnych nadchodzących wydarzeń 👀
            </Alert>
          )
        )}
        <EventModal />
      </section>
      <Footer />
    </>
  );
};
