import { endpoints, useFetchedEvents } from "api";
import { useContext } from "react";
import { TixboxContext } from "store";
import { removeHTMLTags } from "helpers";

const filterEventList = (eventList, filterSet) =>
  filterSet && Object.keys(filterSet).length
    ? eventList.filter((event) => {
        for (const key in filterSet) {
          const filters = filterSet[key] || [];
          const eventValue = removeHTMLTags(event[key]);
          const selectedFilters = filters.filter((f) => f.isSelected);
          const filterValues = selectedFilters.map(({ name }) => name);

          if (!filterValues.includes(eventValue)) return false;
        }
        return true;
      })
    : eventList;
export const useFilteredEvents = () => {
  const { events, aggregators, queryResult } = useFetchedEvents();
  const {
    state: {
      ui: { filterSet, apiMode },
    },
  } = useContext(TixboxContext);

  const filteredEvents =
    apiMode === endpoints.search ? filterEventList(events, filterSet) : events;

  const filteredAggregators = [...aggregators]

  return {filteredEvents, filteredAggregators, filterSet, queryResult};
};
