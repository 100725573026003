import { Badge, ListGroup, Stack } from "react-bootstrap";

import { usePickedItems } from "hooks";
import { HoveredBg, IconButton, Widget } from "components";
import * as PropTypes from "prop-types";

const HiddenEventsBadge = ({ number }) => (
  <Badge
    className="ms-2"
    bg="danger"
    pill
    title={`${number} niewidoczne na podglądzie`}
  >
    {number}
  </Badge>
);

HiddenEventsBadge.propTypes = {
  number: PropTypes.number,
};
const EventsList = () => {
  const { pickedEvents, setPickedEvents } = usePickedItems();
  return (
    <ListGroup as="ol" numbered variant="flush">
      {pickedEvents.map(({ slug, name }) => (
        <HoveredBg
          as={ListGroup.Item}
          key={slug}
          className="d-flex"
          $hoveredBg="var(--bs-tertiary-bg)"
        >
          <Stack direction="horizontal" gap={3} className="flex-grow-1">
            <div className="flex-grow-1 ms-2 border-opacity-10 border-secondary border-end">
              <div className="fw-bold">{name}</div>
              <span className="text-secondary">{slug}</span>
            </div>
            <IconButton
              onClick={() => setPickedEvents({ slug, name })}
              variant="link"
              className="text-danger"
              title="Usuń wybór"
              icon="trash3"
            />
          </Stack>
        </HoveredBg>
      ))}
    </ListGroup>
  );
};
const EventsInfo = () => {
  return (
    <div className="text-secondary">
      Kliknij przycisk{" "}
      <i className="bi bi-plus bg-primary text-light rounded rounded-5 d-inline-block px-1 mx-1" />{" "}
      przy kafelku wydarzenia na{" "}
      <a href={`#${Widget.EventsLP.name}`}>karcie podglądu</a>, aby wybrać
      pojedyncze wydarzenia.
    </div>
  );
};

export const EventsHeader = ({
  iframeConfig,
  pickedAmount,
  hiddenNumber,
  title,
}) => (
  <>
    {title}
    {iframeConfig.q && (
      <div className="me-4">
        {!pickedAmount && <span className="small me-2">wszystko z</span>}
        <Badge pill title={`${pickedAmount} wybrane`}>
          {pickedAmount || iframeConfig.q}
        </Badge>
        {!!hiddenNumber && <HiddenEventsBadge number={hiddenNumber} />}
      </div>
    )}
  </>
);

EventsHeader.propTypes = {
  iframeConfig: PropTypes.any,
  pickedAmount: PropTypes.number,
};

export const Picked = {
  EventsList,
  EventsInfo,
  EventsHeader,
};
