import { A_TYPE } from "store";

const { READ } = A_TYPE;
export const readReducer = ({ action, state }) => {
  const { type, payload } = action;
  if (type === READ.UrlSearch) {
    return {
      ...state,
      iframeConfig: {
        ...state.iframeConfig,
        ...payload,
      },
    };
  }
};
