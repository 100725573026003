import { useContext } from "react";
import { action, handleDispatch, TixboxContext } from "store";

export const useIframeProp = (prop = "") => {
  const {
    state: { iframeConfig },
    dispatch,
  } = useContext(TixboxContext);

  const actionMeta = { prop };
  return [
    iframeConfig[prop],
    handleDispatch(dispatch)(action.changed[prop], actionMeta),
  ];
};
