import { createBrowserRouter } from "react-router-dom";

import TixboxSetup from "views/App";
import { EventsView } from "components";

export const routePath = {
  eventsView: "/",
  tixboxSetup: "/tixbox-setup",
};
export const router = createBrowserRouter([
  { path: routePath.eventsView, element: <EventsView /> },
  {
    path: routePath.tixboxSetup,
    element: <TixboxSetup />,
  },
]);
