import dayjs from "dayjs";
import * as PropTypes from "prop-types";
import styled from "styled-components";

const StyledEventDetails = styled.dl`
  opacity: 0.75;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;

  .event-date {
    font-size: 0.9em;
    opacity: 0.8;
  }
  .event-location {
    margin-bottom: 0;
    &__place {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
`;
export const EventDetails = ({ date, place, city }) => {
  const dayjsDate = dayjs(date);
  const displayedDate = dayjsDate.isValid() ? dayjsDate.format("LL") : date;
  return (
    <StyledEventDetails className="mb-0">
      <dt className="visually-hidden">Data wydarzenia:</dt>
      <dd className="event-date mb-1">{displayedDate}</dd>
      <dt className="visually-hidden">Lokalizacja:</dt>
      <dd className="event-location">
        {city && place ? (
          <>
            {city}, <div className="event-location__place">{place}</div>
          </>
        ) : (
          city || place
        )}
      </dd>
    </StyledEventDetails>
  );
};

EventDetails.propTypes = {
  date: PropTypes.string,
  place: PropTypes.string,
  city: PropTypes.string,
};
