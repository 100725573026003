import { Form } from "react-bootstrap";

export const Switch = ({
  id,
  controlLabel,
  defaultChecked,
  onChange,
  className = "",
}) => {
  return (
    <Form.Check
      type="switch"
      id={id}
      label={controlLabel}
      defaultChecked={defaultChecked}
      onChange={({ target }) => onChange(target.checked)}
      className={className}
    />
  );
};
