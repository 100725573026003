import { useContext, useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";

import { useIframeProp } from "hooks";
import { action, handleDispatch, TixboxContext } from "store";
import { Switch } from "components";

export const ContentHtml = ({
  property,
  initialSetup,
  defaultContent = initialSetup.__html,
}) => {
  const [content, setContent] = useIframeProp(property);
  const [isTextEdited, setIsTextEdited] = useState(false);
  const {
    state: { iframeConfig },
    dispatch,
  } = useContext(TixboxContext);

  const { toggle } = initialSetup;

  const handleToggle = (toggle) =>
    handleDispatch(dispatch)(action.changed[property])(
      toggle ? defaultContent : false
    );

  useEffect(() => {
    setIsTextEdited(content !== defaultContent);
  }, [content, defaultContent]);

  return (
    <>
      <Switch
        {...toggle}
        onChange={handleToggle}
        defaultChecked={iframeConfig[property]}
        className="mb-3"
      />
      {content !== false && (
        <Form.Group className="d-flex flex-column align-items-end">
          <FloatingLabel label="Tekst lub html" className="mb-2 w-100">
            <Form.Control
              as="textarea"
              placeholder="Text"
              className="code"
              style={{ height: "10em" }}
              value={iframeConfig[property]}
              onChange={setContent}
            />
          </FloatingLabel>
          {isTextEdited && (
            <Button
              variant="warning"
              size="sm"
              onClick={() => setContent(defaultContent)}
            >
              <i className="bi bi-arrow-counterclockwise" /> Przywróć
            </Button>
          )}
        </Form.Group>
      )}
    </>
  );
};
