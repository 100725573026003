import { Col, Container, Navbar, Row } from "react-bootstrap";
import { TixboxContext } from "store";
import { Widget } from "components";
import { useContext } from "react";
import styled from "styled-components";

const navbarHeight = "56px";
const StyledEventsLP = styled(Widget.EventsLP)`
  --offset-top: ${navbarHeight};
  min-height: 50vh;
`;

const App = () => {
  const {
    state: {
      // iframeConfig,
      ui: {
        horizontallyExpandedWidgets: expandedWidgets,
        // apiMode,
        // pickedEvents,
      },
    },
  } = useContext(TixboxContext);
  const ExampleLPId = Widget.EventsLP.name;
  const isExampleExpanded = expandedWidgets.includes(ExampleLPId);
  // const isPickedAnyEvent = pickedEvents.length;
  // const mode = isPickedAnyEvent ? endpoints.events : apiMode;

  // const [params, setParams] = useState("");

  // useEffect(() => {
  //   setParams(() => getParams(mode, pickedEvents, iframeConfig));
  // }, [mode, pickedEvents, iframeConfig]);
  return (
    <>
      <Navbar bg="dark" variant="dark" sticky="top">
        <Container>
          <Navbar.Brand>Biletomat Tixbox configurator</Navbar.Brand>
          {/*<Link to={routePath.eventsView + "?" + params}>*/}
          {/*  <Button variant="outline-light">Preview</Button>*/}
          {/*</Link>*/}
        </Container>
      </Navbar>
      <Container className="pt-5" as="main">
        <Row>
          <Col xl={6} className="mb-4">
            <Widget.Configuration title="Skonfiguruj" />
          </Col>
          <Col xl={6} className="mb-4 d-flex">
            <Widget.GeneratedCode title="Skopiuj kod" />
          </Col>
        </Row>
      </Container>
      <Container fluid={isExampleExpanded}>
        <Row>
          <Col className={isExampleExpanded ? "ps-0 pe-0" : ""}>
            <StyledEventsLP
              widgetId={ExampleLPId}
              isExpanded={isExampleExpanded}
              title="Sprawdź, czy jest ładnie"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default App;
