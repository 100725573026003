import styled from "styled-components";
import { useSearchItems } from "hooks";

import { AggregatorItem } from ".";

const StyledEventList = styled.ul`
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 15px;
  list-style: none;
  display: grid;
  grid-gap: 25px;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(24em, 1fr));
  }
`;

export const AggregatorsList = ({ aggregators, searchQuery }) => {
  const { searchResults } = useSearchItems(aggregators, searchQuery);

  return (
    <>
      <StyledEventList>
        {
          searchResults?.map((aggregateProps, i) => <AggregatorItem {...aggregateProps} key={`aggregator-item-${i}`} />)
        }
      </StyledEventList>
    </>
  );
};
