import { Card, Form } from "react-bootstrap";

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { EventsView, IconButton, ToggleFullscreen } from "components";

import { useIframeBgColor } from "hooks";

dayjs.extend(LocalizedFormat);
const BackgroundControl = ({ color = "#ffffff", onChange, className }) => {
  return (
    <Form.Control
      className={className}
      type="color"
      value={color === "transparent" ? "#ffffff" : color}
      title="Zmień kolor tła"
      onChange={({ target }) => onChange(target.value)}
    />
  );
};

export const EventsLP = ({ widgetId, isExpanded, title, className }) => {
  const { bgColor, setBgColor } = useIframeBgColor("transparent");

  return (
    <Card
      id={EventsLP.name}
      className={
        `mb-5 ${isExpanded ? "border-start-0 border-end-0 " : ""}` + className
      }
    >
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>
          <i className="bi bi-2-circle-fill me-2"></i>
          {title}
        </span>
        <ToggleFullscreen widgetId={widgetId} isFullscreen={isExpanded} />
      </Card.Header>
      <Card.Body
        className={`d-flex flex-column position-relative pb-0 ${
          isExpanded ? "ps-0 pe-0" : ""
        }`}
        style={{ backgroundColor: bgColor }}
      >
        <header>
          <div className="position-absolute end-0 top-0 me-3 mt-3 d-flex">
            <BackgroundControl color={bgColor} onChange={setBgColor} />
            {bgColor !== "transparent" && (
              <IconButton
                onClick={() => setBgColor("transparent")}
                title="Reset"
                icon="x-circle-fill"
                variant="light"
                className="text-danger ms-2"
              />
            )}
          </div>
        </header>
        <EventsView className="flex-grow-1" />
      </Card.Body>
    </Card>
  );
};
