import { useContext, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { action, handleDispatch, TixboxContext } from "store";
import { endpoints } from "api";
import { TabEventsApi } from "./tab-events-api";
import { TabSearchApi } from "./tab-search-api";

export const DataSourceSetup = () => {
  const { dispatch } = useContext(TixboxContext);
  const [tab, setTab] = useState(endpoints.eventsList);
  const handleSwitchApiMode = (mode) => {
    handleDispatch(dispatch)(action.ui.apiMode)(mode);
    setTab(mode);
  };

  return (
    <Tabs
      defaultActiveKey={tab}
      id="data-source-setup"
      onSelect={handleSwitchApiMode}
      variant="pills"
    >
      <Tab
        eventKey={endpoints.eventsList}
        title="Lista wydarzeń"
        className="px-3 py-4 my-3 bg-light rounded-3"
        style={{ minHeight: "5em" }}
        disabled={tab === endpoints.eventsList}
      >
        {tab === endpoints.eventsList && <TabEventsApi />}
      </Tab>
      <Tab
        eventKey={endpoints.search}
        title="Indywidualny wybór"
        className="px-3 py-4 my-3 bg-light rounded-3"
        disabled={tab === endpoints.search}
      >
        {tab === endpoints.search && <TabSearchApi />}
      </Tab>
    </Tabs>
  );
};
