// noinspection SpellCheckingInspection
// TODO: refactor as a translation file eg. settings/translation

export const colors = {
  biletomat: { rgb: "255,102,1", hex: "#FF6601" },
};
const settings = {
  orderPageType: {
    controlLabel: "Otwieraj stronę zamówienia w nowej karcie (na biletomat.pl)",
    id: "order-page-type",
  },
  searchBar: {
    controlLabel: "Pokaż/ukryj wyszukiwarkę wydarzeń",
    id: "toggle-search-bar",
  },
  partnerEvents: {
    controlLabel: {
      partner: "partnera",
      all: "wszystkie",
    },
    id: "partner-events-scope",
  },
  partnerCode: {
    formLabel: "Kod partnera",
    placeholder: "Kod partnera (ck)",
    id: "ck",
    defaultValue: "tb",
  },
  eventSearch: {
    showFilters: "filtrowanie",
    query: {
      formLabel: "Szukana fraza",
      id: "search-query",
      defaultValue: "stand-up",
    },
  },
  colors: [
    {
      formLabel: "Tło kafelka",
      id: "background",
      defaultValue: "#f5f5f5",
    },
    {
      formLabel: "Kolor tekstu kafelka",
      id: "color",
      defaultValue: "#181818",
    },
    {
      formLabel: "Tło buttona",
      id: "buttonBg",
      defaultValue: colors.biletomat.hex,
    },
    {
      formLabel: "Kolor tekstu buttona",
      id: "buttonColor",
      defaultValue: "#fdfdfd",
    },
  ],
  misc: {
    tileButtonText: {
      formLabel: "Etykieta przycisków na kafelkach",
      placeholder: "Etykieta przycisków na kafelkach",
      id: "btn-text",
      defaultValue: "Kup Bilet",
    },
    header: {
      __html: false,
      __htmlDefault:
        `<h1 class="display-5 fw-bold">Biletomat.pl</h1>\n` +
        `<p class="col-md-8 fs-4">\n` +
        "Znajdź pasjonujące wydarzenia na Biletomat.pl! Wybierz swoje ulubione " +
        "koncerty, festiwale, spektakle i wydarzenia kulturalne i zarezerwuj " +
        "bilety online, by cieszyć się niezapomnianymi chwilami w gronie " +
        "artystycznych i muzycznych emocji!\n" +
        "</p>",
      toggle: {
        controlLabel: "Pokaż/ukryj nagłówek",
        id: "toggle-header",
      },
    },
    footer: {
      __html: `<div>Organizujesz imprezę, koncert, festiwal?<br/><a href="https://www.biletomat.pl/wspolpraca/" target="_blank" title="Sprawdź ofertę Biletomat.pl" class="text-decoration-none">Sprawdź ofertę Biletomat.pl</a></div>`,
      logoLink: "https://www.biletomat.pl/",
      toggle: {
        controlLabel: "Pokaż/ukryj stopkę",
        id: "toggle-footer",
      },
    },
  },
};

export default settings;
