import { Button } from "react-bootstrap";
import { oneOf, string, func } from "prop-types";

import bootstrapIcons from "bootstrap-icons/font/bootstrap-icons.json";

export const IconButton = ({
  variant,
  size,
  icon,
  title,
  onClick,
  className,
  style,
}) => (
  <Button
    className={className}
    variant={variant}
    size={size}
    title={title}
    onClick={onClick}
    style={style}
  >
    <i className={`bi bi-${icon}`}></i>
  </Button>
);
const icons = Object.keys(bootstrapIcons);
IconButton.propTypes = {
  size: oneOf(["sm", "md", "lg"]),
  variant: oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
    "light",
    "link",
    "outline-primary",
    "outline-secondary",
    "outline-success",
    "outline-danger",
    "outline-warning",
    "outline-info",
    "outline-dark",
    "outline-light",
  ]),
  icon: oneOf(icons).isRequired,
  title: string,
  onClick: func.isRequired,
  className: string,
};
