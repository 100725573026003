import { useContext } from "react";
import * as PropTypes from "prop-types";

import { action, createAction, TixboxContext } from "store";
import { Styled } from "./StyledComponents";
export const CTAButton = ({ isDisabled, url }) => {
  const {
    state: {
      iframeConfig: { buttonLabel, targetBlank, buttonBg, buttonColor },
    },
    dispatch,
  } = useContext(TixboxContext);
  const openModal = (url) => {
    dispatch(createAction(action.eventModal.toggle));
    dispatch(createAction(action.eventModal.setOrderUrl, url));
  };
  const handleClick = () => {
    if (targetBlank) {
      window.open(url, "_blank");
      return;
    }
    openModal(url);
  };

  return (
    <Styled.CTA
      aria-disabled={isDisabled}
      onClick={handleClick}
      $buttonBg={buttonBg}
      $btnCol={buttonColor}
    >
      {buttonLabel}
    </Styled.CTA>
  );
};

CTAButton.propTypes = {
  isDisabled: PropTypes.bool,
  url: PropTypes.string,
};
