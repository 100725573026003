import { A_TYPE, initialState } from "store";

const { CHANGED } = A_TYPE;
const mutateIframeConfigProp = (setting, payload, state) => ({
  ...state,
  iframeConfig: {
    ...state.iframeConfig,
    [setting]: payload,
  },
});

const processPayload = {
  footerHtml(readVal) {
    if (readVal === undefined) return initialState.iframeConfig.footerHtml;
    if (readVal === "false") return false;
    return readVal;
  },
};

const mutateIframeSetup = (setting, payload, state) => {
  const processFn = (value) =>
    processPayload[setting] ? processPayload[setting](value) : value;

  const { iframeConfig } = mutateIframeConfigProp(
    setting,
    processFn(payload),
    state
  );
  return { ...state, iframeConfig };
};

export const changedReducer = ({ action, state }) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGED.PartnerCode:
      return mutateIframeSetup("partnerCode", payload, state);
    case CHANGED.IsLimitedToPartnerEvents:
      return mutateIframeSetup("isLimitedToPartnerEvents", payload, state);
    case CHANGED.City:
      return mutateIframeSetup("city", payload, state);
    case CHANGED.ButtonBg:
      return mutateIframeSetup("buttonBg", payload, state);
    case CHANGED.ButtonColor:
      return mutateIframeSetup("buttonColor", payload, state);
    case CHANGED.Background:
      return mutateIframeSetup("background", payload, state);
    case CHANGED.Color:
      return mutateIframeSetup("color", payload, state);
    case CHANGED.TargetBlank:
      return mutateIframeSetup("targetBlank", payload, state);
    case CHANGED.SearchBar:
      return mutateIframeSetup("searchBar", payload, state);
    case CHANGED.ButtonLabel:
      return mutateIframeSetup("buttonLabel", payload, state);
    case CHANGED.ImageBorderRadius:
      return mutateIframeSetup("imageBorderRadius", payload, state);
    case CHANGED.ImageSize:
      return mutateIframeSetup("imageSize", payload, state);
    case CHANGED.SearchQuery:
      return mutateIframeSetup("q", payload, state);
    case CHANGED.FooterHtml:
      return mutateIframeSetup("footerHtml", payload, state);
    case CHANGED.Header:
      return mutateIframeSetup("header", payload, state);
    default:
      return state;
  }
};
