import styled from "styled-components";

const PolymorphicComponent = ({ as: Component, ...rest }) => (
  <Component {...rest} />
);

export const HoveredBg = styled(PolymorphicComponent)`
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background: ${({ $hoveredBg }) => $hoveredBg};
  }
`;
