import React from "react";
import ReactDOM from "react-dom/client";

import dayjs from "dayjs";
import "dayjs/locale/pl";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";

import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import reportWebVitals from "./reportWebVitals";
import { debugContextDevtool } from "react-context-devtool";
import { TixboxProvider } from "./store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing";

const queryClient = new QueryClient();
dayjs.locale("pl");

const appRoot = document.getElementById("root");
const root = ReactDOM.createRoot(appRoot);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <TixboxProvider>
        <RouterProvider router={router} />
      </TixboxProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);

debugContextDevtool(appRoot);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
