export const Loader = ({ alt = "loading…", isLoading = true, sm = false }) =>
  isLoading && (
    <div className="flex-grow-1 d-flex align-items-center justify-content-center h-100">
      <div
        className="loader"
        style={sm ? { width: "2.5em", height: "2.5em" } : undefined}
      >
        <img className="loader__img" src="/logo192.png" alt={alt} />
      </div>
    </div>
  );
