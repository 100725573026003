import { A_TYPE } from "store";

const { EVENT_MODAL } = A_TYPE;
export const eventModalReducer = ({ action, state }) => {
  const { type, payload } = action;
  switch (type) {
    case EVENT_MODAL.Toggle: {
      const { eventModal } = state;
      return {
        ...state,
        eventModal: { ...eventModal, show: !state.eventModal.show },
      };
    }
    case EVENT_MODAL.SetOrderUrl: {
      const { eventModal } = state;
      if (!payload)
        return { ...state, eventModal: { ...eventModal, eventUrl: "" } };
      const { origin, pathname } = new URL(payload);
      return {
        ...state,
        //eventModal: { ...eventModal, eventUrl: origin + pathname },
        eventModal: { ...eventModal, eventUrl: payload },
      };
    }
    default:
      return state
  }
};
