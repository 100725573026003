import { A_TYPE } from "./actions";
import { initialState } from "./initial";
import reducers from "./reducers";

const getReducer = ({ type }) => {
  const [reducerType] = Object.entries(A_TYPE).find(
    ([, props]) => Object.values(props).filter((value) => value === type).length
  );
  return reducers[reducerType];
};

export const tixboxReducer = (state = initialState, action) => {
  return getReducer(action)({ action, state });
}
