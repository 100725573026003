import SyntaxHighlighter from "react-syntax-highlighter";
import { qtcreatorDark as codeStyle } from "react-syntax-highlighter/dist/cjs/styles/hljs";

export const CodeEmbed = ({ code, language = "htmlbars", style}) => {
  return (
    <SyntaxHighlighter
      wrapLongLines={true}
      language={language}
      style={{
        ...codeStyle,
        hljs: {
          ...codeStyle.hljs,
          background: "rgba(0,0,0,0.2)",
          margin: 0,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#fff2",
          borderRadius: 5,
          transition: "border 0.5s ease-in-out",
          ...style
        }
      }}
    >
      {code}
    </SyntaxHighlighter>
  );
};
