import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

import content, { colors } from "tixboxSettingsContent";
import SVG from "svg";
import { useIframeProp } from "hooks";
import { initialState } from "store";

import { getThemeVariant } from "helpers";

const useFooterContent = () => {
  const [footerContent, setFooterContent] = useIframeProp("footerHtml");

  useEffect(() => {
    if (footerContent === undefined)
      setFooterContent(initialState.iframeConfig.footerHtml);
  }, [footerContent, setFooterContent]);

  return footerContent;
};
const Footer = ({ className }) => {
  const footerContent = useFooterContent();
  const isFooterVisible = !!footerContent || footerContent === "";

  return (
    isFooterVisible && (
      <Container as="footer" fluid className={className}>
        <Row className="px-4 py-1">
          <Col
            sm={9}
            className="d-flex align-items-center"
            dangerouslySetInnerHTML={{ __html: footerContent }}
          />
          <Col sm={3} className="text-center text-md-end">
            <a href={content.misc.footer.logoLink}>
              <SVG.Logo
                style={{ maxWidth: "10rem", minWidth: "5rem", height: "100%" }}
              />
            </a>
          </Col>
        </Row>
      </Container>
    )
  );
};

const footerTheme = {
  default: "--bg: #ddda; --fg: #000",
  light: "--bg: #fffa; --fg: #000",
  dark: "--bg: #000a; --fg: #aaa",
};

const StyledFooter = styled(Footer)`
  ${getThemeVariant(footerTheme)};
  --bs-link-color-rgb: ${colors.biletomat.rgb};
  background-color: var(--bg, #aaaa);
  color: var(--fg, #000);
  border-top: solid 1px #ccc;
  position: sticky;
  bottom: 0;
  backdrop-filter: blur(20px);
  z-index: 999;
  border-radius: 5px 5px 0 0;
  padding: var(--bs-card-spacer-y) 1em;

  .site-logo {
    width: 300px;
    height: 100px;
  }
`;
export { StyledFooter as Footer };
