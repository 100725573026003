const CHANGED = {
  PartnerCode: "[CHANGED]_partnerCode",
  City: "[CHANGED]_city",
  Category: "[CHANGED]_category",
  IsLimitedToPartnerEvents: "[CHANGED]_isLimitedToPartnerEvents",
  ButtonColor: "[CHANGED]_buttonColor",
  ButtonBg: "[CHANGED]_buttonBg",
  ButtonLabel: "[CHANGED]_buttonLabel",
  ImageSize: "[CHANGED]_imageSize",
  ImageBorderRadius: "[CHANGED]_imageBorderRadius",
  Background: "[CHANGED]_background",
  Color: "[CHANGED]_color",
  TargetBlank: "[CHANGED]_targetBlank",
  IframeUrl: "[CHANGED]_iframeUrl",
  SearchQuery: "[CHANGED]_searchQuery",
  SearchBar: "[CHANGED]_searchBar",
  FooterHtml: "[CHANGED]_footerHtml",
  Header: "[CHANGED]_header",
};

const UI = {
  ExpandWidget: "[UI]_expandWidget",
  ApiMode: "[UI]_apiMode",
  SetFilters: "[UI]_setFilters",
  PickEvent: "[UI]_pickEvent",
  PickAggregator: "[UI]_pickAggregator",
  IframeStyle: "[UI]_iframeStyle",
};

const EVENT_MODAL = {
  Toggle: "[EVENT_MODAL]_toggle",
  EventUrl: "[EVENT_MODAL]_eventUrl",
  SetOrderUrl: "[EVENT_MODAL]_setOrderUrl",
};

const READ = {
  UrlSearch: "[READ]_urlSearch",
};

const DEV = {
  UnknownAction: "[DEV]_Unknown_action_dispatched",
};
export const A_TYPE = {
  CHANGED,
  UI,
  EVENT_MODAL,
  READ,
  DEV,
};

export const action = {
  notSpecified: { type: DEV.UnknownAction },
  changed: {
    partnerCode: { type: CHANGED.PartnerCode },
    city: { type: CHANGED.City },
    category: { type: CHANGED.category },
    isLimitedToPartnerEvents: { type: CHANGED.IsLimitedToPartnerEvents },
    buttonColor: { type: CHANGED.ButtonColor },
    buttonBg: { type: CHANGED.ButtonBg },
    buttonLabel: { type: CHANGED.ButtonLabel },
    imageSize: { type: CHANGED.ImageSize },
    imageBorderRadius: { type: CHANGED.ImageBorderRadius },
    background: { type: CHANGED.Background },
    color: { type: CHANGED.Color },
    targetBlank: { type: CHANGED.TargetBlank },
    iframeUrl: { type: CHANGED.IframeUrl },
    searchQuery: { type: CHANGED.SearchQuery },
    searchBar: { type: CHANGED.SearchBar },
    footerHtml: { type: CHANGED.FooterHtml },
    header: { type: CHANGED.Header },
  },
  ui: {
    expandWidget: { type: UI.ExpandWidget },
    apiMode: { type: UI.ApiMode },
    setFilters: { type: UI.SetFilters },
    pickEvent: { type: UI.PickEvent },
    pickAggregator: { type: UI.PickAggregator },
    iframeStyle: { type: UI.IframeStyle },
  },
  eventModal: {
    toggle: { type: EVENT_MODAL.Toggle },
    setOrderUrl: { type: EVENT_MODAL.SetOrderUrl },
  },
  read: {
    urlSearch: { type: READ.UrlSearch },
  },
};

export function createAction(actionType) {
  const [, payload] = arguments;
  const value = !payload?.target ? payload : payload.target.value;
  if (payload === undefined) return { ...actionType };
  return { ...actionType, payload: value };
}
