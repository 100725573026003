import { createContext, useReducer } from "react";
import { tixboxReducer, initialState } from "store";
export const TixboxContext = createContext({ state: initialState });

export const TixboxProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tixboxReducer, initialState, undefined);
  return (
    <TixboxContext.Provider value={{ state, dispatch }}>
      {children}
    </TixboxContext.Provider>
  );
};
