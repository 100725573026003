import { useContext, useEffect } from "react";
import { action, handleDispatch, TixboxContext } from "store";

export const useIframeBgColor = (color = undefined) => {
  const {
    state: {
      ui: { iframeStyle },
    },
    dispatch,
  } = useContext(TixboxContext);

  const propName = "background-color";
  const handleChangeBg = (dispatch) =>
    handleDispatch(dispatch)(action.ui.iframeStyle);
  const getBgProp = (v, k = propName) => ({ [k]: v });
  const setStyleProp = (value) => handleChangeBg(dispatch)(getBgProp(value));

  useEffect(() => {
    handleChangeBg(dispatch)(getBgProp(color));
  }, [color, dispatch]);

  const bgColor = iframeStyle[propName];

  return { bgColor, setBgColor: setStyleProp };
};
