import { useContext, useMemo } from "react";
import { useLocation, useMatch } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";

import { TixboxContext } from "store";
import { extractApiParams } from "helpers";
import { routePath } from "routing";

export const endpoints = {
  search: "search",
  eventsList: "events-list",
  events: "events",
};

const endpointParams = {
  search: { q: "q" },
  eventsList: { ck: "ck", my: "my" },
  events: { ck: "ck", events: "events" },
};

const removeTrailingBracesAndParse = (data = "") => {
  const regex = /^[(]?([\s\S]*?)[)]?;?$/;
  return JSON.parse(data.replace(regex, "$1"));
};

const isBiletomatApiResponse = (data) => data[0] === "(";

const queryFn = async ({ queryKey }) => {
  const [endpoint, searchString] = queryKey;
  const response = await axios.get(`/${endpoint}?${searchString}&callback&sg=1`);
  return isBiletomatApiResponse(response.data)
    ? removeTrailingBracesAndParse(response.data)
    : response.data;
};

const getEndpointType = (sp = new URLSearchParams()) => {
  const { events, eventsList, search } = endpointParams;

  const isEventsList =
    sp.has(eventsList.ck) && sp.has(eventsList.my);
  const isEvents = sp.has(events.events);
  const isSearch = sp.has(search.q) && !sp.has(events.events);

  if (isEventsList) return endpoints.eventsList;
  if (isEvents) return endpoints.events;
  if (isSearch) return endpoints.search;
};

const useApi = (mode = undefined) => {
  const {
    state: {
      iframeConfig,
      ui: { apiMode },
    },
  } = useContext(TixboxContext);
  const selectedMode = mode || apiMode;

  const memoizedParams = useMemo(
    () => extractApiParams(iframeConfig, selectedMode),
    [iframeConfig, selectedMode]
  );
  const [debouncedEndpoint, apiParams] = useDebounce(
    [selectedMode, memoizedParams],
    500
  );
  let endpoint = debouncedEndpoint;

  const location = useLocation();
  const isPublicView = !!useMatch(routePath.eventsView);
  const apiSearchParams = isPublicView ? location.search : apiParams;
  const apiURLSearchParams = new URLSearchParams(apiSearchParams);

  if (isPublicView) {
    endpoint = getEndpointType(apiURLSearchParams);
    
  }
  
  return useQuery({
    queryKey: [endpoint, apiURLSearchParams.toString()],
    queryFn,
    enabled: !!apiURLSearchParams,
    staleTime: 5000,
  });
};
export const useFetchedEvents = () => {
  const queryResult = useApi();
  const { data } = queryResult;

  const aggregators = data?.aggregators || []

  const events =
    (data?.hasOwnProperty("pools") && data.pools?.map(({ event }) => event)) ||
    (data?.hasOwnProperty("events") && data.events) ||
    [];

  return { queryResult, events, aggregators };
};
