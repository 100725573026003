import { useContext } from "react";
import { action, handleDispatch, TixboxContext } from "store";

export const usePickedItems = (slug = "") => {
  const {
    state: {
      ui: { pickedEvents, pickedAggregators },
    },
    dispatch,
  } = useContext(TixboxContext);

  const isPickedEvent = (checkedSlug) =>
    pickedEvents.map((event) => event.slug).includes(checkedSlug);

  const isPickedAggregator = (checkedSlug) =>
  pickedAggregators.map((aggregator) => aggregator.slug).includes(checkedSlug);    

  const setPickedEvents = (event) =>
    handleDispatch(dispatch)(action.ui.pickEvent)({
      event,
      isPickedEvent: !isPickedEvent(event.slug),
    });

  const setPickedAggregators = (aggregator) =>
    handleDispatch(dispatch)(action.ui.pickAggregator)({
      aggregator,
      isPickedAggregator: !isPickedAggregator(aggregator.slug),
    });

  return {
    isPickedEvent: isPickedEvent(slug),
    isPickedAggregator: isPickedAggregator(slug),
    pickedEvents,
    pickedAggregators,
    setPickedEvents,
    setPickedAggregators,
  };
};
