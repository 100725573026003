import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as Logo } from "./logo-biletomat.svg";
import { colors } from "tixboxSettingsContent";
import { getThemeVariant } from "helpers";

export const logo = ["default", "mono-light", "mono-dark"];
const [def, monoLight, monoDark] = logo;

const logoVariants = {
  [def]: `--logo-foreground: #ffffff; --logo-background: ${colors.biletomat.hex};`,
  [monoLight]: "--logo-foreground: #000; --logo-background: #fff;",
  [monoDark]: "--logo-foreground: #fff; --logo-background: #000;",
};

const StyledLogo = styled(Logo)`
  ${getThemeVariant(logoVariants)}
  width: 100%;
`;

StyledLogo.propTypes = {
  $variant: PropTypes.oneOf(logo),
};

StyledLogo.defaultProps = {
  $variant: def,
};

const SVG = { Logo: StyledLogo };
export default SVG;
