import { apiParams } from "store/initial";

export const cleanUndef = (obj) =>
  Object.fromEntries(
    Object.entries(obj)
      .map(([key, val]) => (val === true ? [key, ""] : [key, val]))
      .filter(([key, val]) => val !== undefined && val !== null)
      .map(([key, val]) => [key, val.toString()])
  );
export const shortenParamNames = ({
  city,
  category,
  isLimitedToPartnerEvents,
  partnerCode,
  buttonColor,
  buttonBg,
  buttonLabel,
  imageSize,
  imageBorderRadius,
  background,
  color,
  targetBlank,
  pickedEvents,
  pickedAggregators,
  searchBar,
  ...props
}) => ({
  c: city,
  g: category,
  my: isLimitedToPartnerEvents,
  ck: partnerCode,
  btc: buttonColor,
  bbc: buttonBg,
  bl: buttonLabel,
  is: imageSize,
  ir: imageBorderRadius,
  bc: background,
  fc: color,
  newTab: targetBlank ? "" : undefined,
  events: pickedEvents,
  aggregators: pickedAggregators,
  searchBar: searchBar ? "" : undefined,
  ...props,
});

export const decodeParamNames = ({
  c,
  g,
  my,
  ck,
  btc,
  bbc,
  bl,
  is,
  ir,
  bc,
  fc,
  searchBar,
  ...props
}) => ({
  city: c,
  category: g,
  isLimitedToPartnerEvents: my,
  partnerCode: ck,
  buttonColor: btc,
  buttonBg: bbc,
  buttonLabel: bl,
  imageSize: is,
  imageBorderRadius: ir,
  background: bc,
  color: fc,
  searchBar: searchBar || searchBar === "",
  ...props,
});

export const extractApiParams = (params, apiMode) => {
  const data = Object.keys(apiParams[apiMode]).reduce((result, key) => {
    if (params.hasOwnProperty(key)) {
      result[key] = params[key];
    }
    return result;
  }, {});
  return cleanUndef(shortenParamNames(data));
};

export const subtractApiParams = (params, apiMode) => {
  const subtractedParams = { ...params };
  const unwantedKeys = Object.entries(apiParams)
    .filter(([key]) => key !== apiMode)
    .map(([, v]) => Object.keys(v))
    .flat();

  unwantedKeys.forEach((key) => {
    delete subtractedParams[key];
  });

  return subtractedParams;
};

export const removeHTMLTags = (htmlString) => {
  const element = document.createElement("div");
  element.innerHTML = htmlString;
  return element.innerText;
};
export const getThemeVariant =
  (theme) =>
  ({ $variant = "default" }) =>
    theme[$variant];
