import styled from "styled-components";
import { Button } from "react-bootstrap";

const transition = "0.2s ease-in-out";

const EventItem = styled.li`
  border-bottom: none;
  border-radius: 10px;
  background: ${({ $background }) => $background};
  box-shadow: 0 0 20px -10px #6660;
  transition: box-shadow ${transition}, transform ${transition},
    opacity ${transition};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  --faded-opacity: ${({ $faded }) => ($faded ? 0.65 : 1)};

  .event-picker {
    display: none;
  }
  &:hover .event-picker,
  &:focus-within .event-picker {
    display: block;
    button:focus {
      outline: solid 2px var(--bs-gray-900);
    }
  }
  &:hover {
    box-shadow: 0 0 25px -7px #6666;
    transform: translate(2px, -2px) scale(1.02);
    z-index: 99;
  }
`;

const EventCover = styled.a`
  display: flex;
  aspect-ratio: 1/1;
  img {
    object-fit: cover;
    object-position: left;
  }
`;

const EventContent = styled.figure`
  display: grid;
  flex: 1;
  grid-template-columns: minmax(7rem, 1fr) minmax(10rem, 2fr);
  grid-column-gap: 15px;
  width: 100%;
  transition: opacity ${transition};
  margin-bottom: 0;
  opacity: var(--faded-opacity, 1);

  figcaption {
    align-content: start;
    color: ${({ $textColor }) => $textColor};
    display: flex;
    flex-direction: column;
    padding: 0;

    .event-title {
      font-size: 1.1em;
      font-weight: 600;
      letter-spacing: -0.03em;

      a {
        color: ${({ $textColor }) => $textColor};
        display: block;
      }
    }
  }
`;

const CTA = styled(Button).attrs({ variant: "secondary", className: "mt-2" })`
  background: ${({ $buttonBg }) => $buttonBg};
  color: ${({ $btnCol }) => $btnCol};
  border-radius: calc(var(--ir, 7) * 1px);
  opacity: var(--faded-opacity, 1);
  padding: 0.5em 1.7em 0.6em;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  display: inline-block;
  border: none;
  font-weight: bold;
`;

const ItemPicker = styled.div`
  position: absolute;
  width: 2em;
  height: 2em;
  right: 0.5em;
  top: 0.5em;
  z-index: 100;

  .bi {
    &-plus,
    &-check,
    &-dash {
      &:hover::before {
        animation: ease-in-out 0.2s scale-in;
      }
    }
  }

  @keyframes scale-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
`;
export const Styled = {
  EventContent,
  EventItem,
  EventCover,
  ItemPicker,
  CTA,
};
