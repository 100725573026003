import { useContext } from "react";
import { action, handleDispatch, TixboxContext } from "store";
import content from "tixboxSettingsContent";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";

export const TabEventsApi = () => {
  const {
    state: { iframeConfig },
    dispatch,
  } = useContext(TixboxContext);

  const { partnerCode, partnerEvents } = content;
  const handleChange = handleDispatch(dispatch);

  return (
    <InputGroup>
      <InputGroup.Text>{partnerCode.formLabel}:</InputGroup.Text>
      <Form.Control
        id={partnerCode.id}
        aria-label={partnerCode.formLabel}
        defaultValue={iframeConfig.partnerCode}
        onChange={handleChange(action.changed.partnerCode)}
      />
      <InputGroup.Text>zakres:</InputGroup.Text>
      <DropdownButton
        variant="primary"
        title={
          iframeConfig.isLimitedToPartnerEvents
            ? partnerEvents.controlLabel.partner
            : partnerEvents.controlLabel.all
        }
        id={partnerEvents.id}
        align="end"
      >
        <Dropdown.Item
          onClick={() =>
            handleChange(action.changed.isLimitedToPartnerEvents)(+true)
          }
        >
          {content.partnerEvents.controlLabel.partner}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            handleChange(action.changed.isLimitedToPartnerEvents)(+false)
          }
        >
          {content.partnerEvents.controlLabel.all}
        </Dropdown.Item>
      </DropdownButton>
    </InputGroup>
  );
};
