import { useContext } from "react";
import { action, handleDispatch, TixboxContext } from "store";
import { Col, Form, Row } from "react-bootstrap";

import { HR, Range, TextInput } from "components";
import content from "tixboxSettingsContent";
import { ContentHtml } from "./ContentHtml";

const RangeSetup = ({ label, onChange, min, max, value, unit }) => (
  <Form.Group>
    <Row>
      <Col>
        <Form.Label column>{label}</Form.Label>
      </Col>
      <Col md={8}>
        <Range
          onChange={onChange}
          min={min}
          max={max}
          defaultValue={value}
          unit={unit}
        />
      </Col>
    </Row>
  </Form.Group>
);

export const Misc = () => {
  const {
    state: { iframeConfig },
    dispatch,
  } = useContext(TixboxContext);
  const handleChange = handleDispatch(dispatch);
  const { tileButtonText, footer, header } = content.misc;

  return (
    <>
      <TextInput
        {...tileButtonText}
        onChange={handleChange(action.changed.buttonLabel)}
        className="mb-3"
      />
      <HR />
      <ContentHtml
        property="header"
        initialSetup={header}
        defaultContent={header.__htmlDefault}
      />
      <HR />
      <ContentHtml
        property="footerHtml"
        initialSetup={footer}
      />
      <HR />
      <RangeSetup
        label="Zaokrąglenie miniatury"
        onChange={handleChange(action.changed.imageBorderRadius)}
        value={iframeConfig.imageBorderRadius}
        max={50}
        unit="%"
      />
      <RangeSetup
        label="Wielkość miniatury"
        onChange={handleChange(action.changed.imageSize)}
        value={iframeConfig.imageSize}
        max={300}
        min={50}
      />
    </>
  );
};
