import content from "tixboxSettingsContent";
import { endpoints } from "api";

export const IFRAME_BASE_URL = window.location.origin;

const getDefaultValue = (colors, id) =>
  colors.find((color) => color.id === id).defaultValue;

export const apiParams = {
  [endpoints.eventsList]: {
    isLimitedToPartnerEvents: 1,
    partnerCode: content.partnerCode.defaultValue,
    city: undefined,
    category: undefined,
  },
  [endpoints.search]: {
    q: "",
  },
  [endpoints.events]: {
    ck: content.partnerCode.defaultValue,
    pickedEvents: [],
    pickedAggregators: [],
  },
};

const getMergedParams = (params = {}) =>
  Object.values(params).reduce((acc, obj) => ({ ...acc, ...obj }), {});

const designProps = {
  buttonColor: getDefaultValue(content.colors, "buttonColor"),
  buttonBg: getDefaultValue(content.colors, "buttonBg"),
  imageSize: 150,
  imageBorderRadius: 0,
  background: getDefaultValue(content.colors, "background"),
  color: getDefaultValue(content.colors, "color"),
};
export const initialState = {
  iframeConfig: {
    ...getMergedParams({ ...apiParams, designProps }),
    buttonLabel: content.misc.tileButtonText.defaultValue,
    targetBlank: false,
    searchBar: true,
    footerHtml: content.misc.footer.__html,
    header: content.misc.header.__html,
  },
  ui: {
    horizontallyExpandedWidgets: [],
    filterSet: {},
    apiMode: endpoints.eventsList,
    pickedEvents: [],
    pickedAggregators: [],
    iframeStyle: [],
  },
  eventModal: {
    eventUrl: "",
    show: false,
  },
};
