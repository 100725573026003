import { Badge, Form } from "react-bootstrap";
import { useId } from "react";

export const Range = ({
  min = 0,
  max = 100,
  onChange,
  defaultValue,
  unit = " px",
}) => {
  const id = useId();
  const mid = Math.floor((Math.abs(min) + max) / 2);
  const position = ((defaultValue - min) / (max - min)) * 100;

  return (
    <div className="tff-range">
      <datalist
        id={`markers-${id}`}
        className="d-flex justify-content-between markers"
      >
        <option value={min}>{min}</option>
        <option value={mid}>{mid}</option>
        <option value={max}>
          {max}{unit}
        </option>
      </datalist>
      <div style={{ position: "relative" }}>
        <div className="tff-range_container">
          <Badge
            bg={+defaultValue === min ? "light" : "primary"}
            text={+defaultValue === min ? "primary" : ""}
            className="tff-range_current-value"
            pill
            style={{ left: `${position}%` }}
          >
            {defaultValue}{unit}
          </Badge>
        </div>
        <Form.Range
          defaultValue={defaultValue}
          list={`markers-${id}`}
          min={min}
          max={max}
          onChange={onChange}
          title={defaultValue}
        />
      </div>
    </div>
  );
};
