import styled from "styled-components";
import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useRef } from "react";

const StyledSearchBar = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: var(--bs-gray-200);
  position: sticky;
  z-index: 999;
  margin: 15px 15px;
  max-width: 29em;
  top: calc(15px + var(--offset-top, 0px));
`;

export const SearchBar = ({ handleSearch, searchQuery }) => {
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <StyledSearchBar as={Row} className="rounded-3 shadow-sm">
      <Form.Label htmlFor="events-search" column xs={1} className="ps-2">
        <i className="bi bi-search ps-2 text-secondary" aria-label="Szukaj" />
      </Form.Label>
      <Col xs={10} className="flex-grow-1 pe-0">
        <Form.Control
          id="events-search"
          type="text"
          placeholder="Szukaj wydarzeń..."
          value={searchQuery}
          onChange={handleSearch}
          className="rounded-start-0"
          ref={inputRef}
        />
      </Col>
    </StyledSearchBar>
  );
};
