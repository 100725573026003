import { A_TYPE } from "store";

const { DEV } = A_TYPE;

export const devReducer = ({ action, state }) => {
  const { type } = action;
  if (type === DEV.UnknownAction) {
    console.warn(`The action for ${JSON.stringify(action.meta)} is unsupported, please, check the reducers.`)
    return state;
  }
};
