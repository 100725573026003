import { useContext } from "react";
import { TixboxContext } from "store";
import { removeHTMLTags } from "helpers";

export const useEventProps = (props) => {
  const {
    state: {
      iframeConfig: { targetBlank },
    },
  } = useContext(TixboxContext);
  const {
    absolute_url,
    description_url,
    order_url,
    tickets_url,
    plain_order_url,
    city,
    date_start,
    logo_400x400,
    name,
    place_city,
    place_name,
    place_address,
    slug,
    small_logo,
    sold_out,
    who_text,
    main_genre,
  } = props;
  const regularOrderPageUrl = order_url || absolute_url || tickets_url;
  const plainOrderPageUrl = plain_order_url || regularOrderPageUrl.replace('/bilety/', '/bilety/zamowienie-plain/');

  const tileLink = targetBlank ? regularOrderPageUrl : plainOrderPageUrl;
  const ctaLink = targetBlank ? regularOrderPageUrl : plainOrderPageUrl;

  return {
    name: removeHTMLTags(name),
    url: { tileLink, ctaLink, productPage: description_url },
    city: removeHTMLTags(place_city || city),
    place: removeHTMLTags(place_name),
    address: removeHTMLTags(place_address),
    date: removeHTMLTags(date_start),
    logoSrc: logo_400x400 || small_logo,
    isSoldOut: sold_out,
    slug,
    whoText: removeHTMLTags(who_text),
    category: main_genre,
  };
};
