import { useContext } from "react";
import { useMatch } from "react-router-dom";
import { useHover } from "@uidotdev/usehooks";

import { routePath } from "routing";
import { action, createAction, TixboxContext } from "store";
import { useEventProps, usePickedItems } from "hooks";

import { CTAButton, EventDetails, ItemPicker } from ".";
import { Styled } from "./StyledComponents";

export const EventTile = (props) => {
  const {
    state: {
      iframeConfig: {
        imageBorderRadius,
        imageSize,
        background,
        color,
        targetBlank,
      },
    },
    dispatch,
  } = useContext(TixboxContext);

  const isSetup = !!useMatch(routePath.tixboxSetup);
  const { name, city, url, date, isSoldOut, place, logoSrc, slug } =
    useEventProps(props);

  const { isPickedEvent, setPickedEvents } = usePickedItems(slug);
  //const { isPickedEvent } = usePickedItems(slug);

  const openModal = (url) => {
    dispatch(createAction(action.eventModal.toggle));
    dispatch(createAction(action.eventModal.setOrderUrl, url));
  };
  const handleTileClick = (event, url) => {
    if (!targetBlank) {
      event.preventDefault();
      openModal(url);
    }
  };

  const [pickerRef, isPickerHovered] = useHover();

  const { tileLink, ctaLink } = url;
  const { target, rel } = {
    target: targetBlank ? "_blank" : undefined,
    rel: targetBlank ? "noreferrer" : undefined,
  };
  const trimmedName =
    name[55] === undefined ? name : `${name.trim().slice(0, 50)}…`;

  return (
    <Styled.EventItem
      role="article"
      className="p-3"
      $textColor={color}
      $background={background}
      $faded={isSetup && slug && !isPickedEvent}
    >
      {isSetup && slug && (
        <div ref={pickerRef}>
          <ItemPicker item={{ slug, name }} isHovered={isPickerHovered} isPicked={isPickedEvent} setPicked={setPickedEvents}  />
        </div>
      )}
      <Styled.EventContent
        $textColor={color}
        style={{
          gridTemplateColumns: `calc(${imageSize} * 1px) minmax(5rem, 1fr)`,
        }}
      >
        <Styled.EventCover
          href={tileLink}
          onClick={(e) => handleTileClick(e, tileLink)}
          target={target}
          rel={rel}
        >
          <img
            src={logoSrc}
            alt={name}
            className="img-thumbnail"
            style={{ borderRadius: `calc(${imageBorderRadius} * 1%)` }}
          />
        </Styled.EventCover>
        <figcaption>
          <h2 className="event-title" title={name}>
            <a
              href={tileLink}
              onClick={(e) => handleTileClick(e, tileLink)}
              target={target}
              rel={rel}
              className="text-decoration-none"
            >
              {trimmedName}
            </a>
          </h2>
          <EventDetails date={date} place={place} city={city} />
        </figcaption>
      </Styled.EventContent>
      <CTAButton isDisabled={isSoldOut} url={ctaLink} />
    </Styled.EventItem>
  );
};
