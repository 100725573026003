import { Col, Row, Tab } from "react-bootstrap";
import { FiltersView, FiltersNav } from ".";
import { endpoints, useFetchedEvents } from "api";
import { Loader } from "components";

const filters = [
  { label: "miasta", id: "city" },
  { label: "kategorie", id: "main_genre" },
];

export const SearchFilters = () => {
  const {
    events,
    queryResult: { isLoading },
  } = useFetchedEvents(endpoints.search);
  const isEmpty = !events?.length;
  return (
    <Tab.Container id="eventFilters" defaultActiveKey={filters[0].id}>
      <Row>
        <Col sm={3} className="border-end mb-3 mb-md-0">
          <FiltersNav properties={filters} isDisabled={isEmpty} />
        </Col>
        <Col sm={9}>
          {isLoading ? (
            <Loader sm={true} />
          ) : (
            <FiltersView properties={filters} isEmpty={isEmpty} />
          )}
        </Col>
      </Row>
    </Tab.Container>
  );
};
