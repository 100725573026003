import { useContext } from "react";
import { action, createAction, TixboxContext } from "../../store";
import { Modal } from "react-bootstrap";

export const EventModal = () => {
  const {
    state: {
      iframeConfig: { buttonLabel },
      eventModal: { eventUrl, show }
    },
    dispatch
  } = useContext(TixboxContext);
  const handleHideModal = () => {
    dispatch(createAction(action.eventModal.toggle));
    dispatch(createAction(action.eventModal.setOrderUrl, ""));
  };
  return (
    <Modal centered show={show} onHide={handleHideModal} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>{buttonLabel}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={eventUrl}
          title={buttonLabel}
          className="w-100 h-100"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};
