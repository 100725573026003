import { Nav } from "react-bootstrap";

export const FiltersNav = ({ properties, isDisabled }) => (
  <Nav variant="pills" className="flex-column">
    {properties.map(({ label, id }) => (
      <Nav.Item key={id}>
        <Nav.Link eventKey={id} className="btn" disabled={isDisabled}>
          {label}
        </Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
);
