import { useContext, useState } from "react";
import { Badge, Card, Offcanvas } from "react-bootstrap";
import copy from "clipboard-copy";
import { CodeEmbed, IconButton } from "components";
import { useIframeCode, usePickedItems } from "hooks";
import { endpoints } from "api";
import { TixboxContext } from "store";

const IframePreview = ({ show, onHide, code }) => {
  const style = "\n  .iframe-container iframe {\n    width: 100%; height: 100%; border: solid 2px var(--bs-code-color) !important; border-radius: 5px;\n  }\n"
  return <Offcanvas show={show} onHide={onHide} scroll backdrop={false} className="w-75">
    <style>{style} {`.ReactQueryDevtools {display: none}`}</style>
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Podgląd działania kodu iframe</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className="d-flex flex-column">
      <CodeEmbed
        code={`<style>${style}</style>\n${code}`} style={{ background: "#000e", height: "10em" }}/>
      <div className="mt-3"><code>.iframe-container</code>:</div>
      <div className="flex-grow-1 w-100 iframe-container" dangerouslySetInnerHTML={{ __html: code }} />
    </Offcanvas.Body>
  </Offcanvas>;
}

export const GeneratedCode = ({ title }) => {
  const [justCopied, setJustCopied] = useState(false);
  const { pickedEvents, pickedAggregators } = usePickedItems();
  const {
    state: {
      ui: { apiMode },
    },
  } = useContext(TixboxContext);
  const isPickedAnyEvent = pickedEvents.length;
  const isPickedAnyAggregator = pickedAggregators.length;
  const mode = isPickedAnyEvent || isPickedAnyAggregator ? endpoints.events : apiMode;

  const [embedCode] = useIframeCode(mode);
  const handleCopyCode = () => {
    setJustCopied(true);
    setTimeout(() => setJustCopied(false), 1000);
  };

  const [showIframeDrawer, setShowIframeDrawer] = useState(false)

  //console.log("GeneratedCode", pickedEvents, pickedAggregators)

  return (
    <Card bg="dark" text="light" id={GeneratedCode.name}>
      <Card.Header className="d-flex">
        <i className="bi bi-3-circle-fill me-2"></i>
        <span className="flex-grow-1">{title}</span>
        <div>
          <span className="text-light opacity-50">api mode</span>
          <Badge bg="secondary" className="ms-2">
            {mode}
          </Badge>
        </div>
      </Card.Header>
      <Card.Body onCopyCapture={handleCopyCode}>
        <CodeEmbed
          code={embedCode}
          style={
            justCopied
              ? {
                borderColor: "var(--bs-success)",
                opacity: 0.8,
              }
              : {}
          }
        />
      </Card.Body>
      <Card.Footer className="text-end">
        <IconButton onClick={() => setShowIframeDrawer(true)} variant="dark" title="Podgląd" icon="eyeglasses" />
        <IconButton
          variant={justCopied ? "outline-success" : "dark"}
          title="Skopiuj kod"
          onClick={async () => {
            await copy(embedCode);
            handleCopyCode();
          }}
          icon={`clipboard${justCopied ? "-check" : ""}`}
        />
      </Card.Footer>
      <IframePreview show={showIframeDrawer} onHide={() => setShowIframeDrawer(false)} code={embedCode} />
    </Card>
  );
};
