import { useContext } from "react";
import { action, handleDispatch, TixboxContext } from "store";
import { IconButton } from "./IconButton";

export const ToggleFullscreen = ({ widgetId, isFullscreen }) => {
  const { dispatch } = useContext(TixboxContext);
  const handleToggleFullscreen = handleDispatch(dispatch)(
    action.ui.expandWidget
  );
  return (
    <IconButton
      size="sm"
      variant="outline-secondary"
      icon={isFullscreen ? "arrows-collapse" : "arrows-expand"}
      style={{ transform: "rotate(90deg)" }}
      onClick={() => handleToggleFullscreen(widgetId)}
    />
  );
};
